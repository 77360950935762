<template>
  <div class="kuang_ping_nwes service-project">
    <div class="px1200 nimen_news">
      <div class="shouye_biapt clas_news_bt">
        <div class="mingz_news"><span><my-link href="/home-layout">首页</my-link> > <my-link href="/serviceProject">关于我们</my-link></span></div>
      </div>
      <div class="chanp_biapt">
        <h2>关于我们</h2>
      </div>
      <div class="news_list_ziye" v-if="firstReservice">
        <ul>
          <li >
            <video :src="httpPath + firstReservice.videoUrl" controls="controls"
                   style="display: block"></video>
            <div class="text right-flex">
              <div class="top-box">
                <h2 :title="firstReservice.title">{{ firstReservice.title }}</h2>
                <p :title="firstReservice.description">{{ firstReservice.description }}</p>
              </div>
              <div class="bottom-box" v-for="item in firstReservice.list" :key="item.id">
                <h2 :title="item.title">{{ item.title }}</h2>
                <p :title="item.content" v-html="item.content"></p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="news_list_ziye" v-if="secondReservice">
        <ul>
          <li >
            <img :src="httpPath + secondReservice.imgUrl" alt="">
            <div class="text">
              <div v-for="item in secondReservice.list.slice(0, 4)" :key="item.id">
                <h2 style="padding-bottom: 12px;" :title="item.title">{{ item.title }}</h2>
                <p style="margin-bottom: 5px" v-html="item.content"></p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="news_list_ziye" v-if="thirdReservice">
        <ul>
          <li >
            <div class="col-xs-12 col-md-6">
              <div class="col-xs-12 col-md-6">
                <img class="img-class-0 img-class-1" :src="httpPath + thirdReservice.imgUrl[0]" alt="">
              </div>
              <div class="col-xs-12 col-md-6">
                <img class="img-class-1" style="height: 135px;margin-bottom: 10px" :src="httpPath + thirdReservice.imgUrl[1]" alt="">
                <img class="img-class-0 img-class-1" style="height: 135px;" :src="httpPath + thirdReservice.imgUrl[2]" alt="">
              </div>
            </div>
            <div class="col-xs-12 col-md-6">
              <div class="text" style="width: 100%">
                <div>
                  <h2 style="padding-bottom: 12px;" :title="thirdReservice.title">{{ thirdReservice.title }}</h2>
                  <p style="margin-bottom: 5px" v-html="thirdReservice.description"></p>
                </div>
              </div>
            </div>

          </li>
        </ul>
      </div>
      <div class="news_list_ziye" v-if="fourthReservice">
        <ul>
          <li >
            <img :src="httpPath + fourthReservice.imgUrl" alt="">
            <div class="text" style="height: auto">
              <h2 :title="fourthReservice.list[0].title">{{ fourthReservice.list[0].title }}</h2>
              <p :title="fourthReservice.list[0].content" v-html="fourthReservice.list[0].content"></p>
            </div>
          </li>
        </ul>
      </div>
      <div class="news_list_ziye" v-if="fiveReservice">
        <ul>
          <li >
            <img :src="httpPath + fiveReservice.imgUrl" alt="">
            <div class="text">
              <h2 :title="fiveReservice.title">{{ fiveReservice.title }}</h2>
              <p :title="fiveReservice.description">{{fiveReservice.description}}</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="news_list_ziye" v-if="sixReservice">
        <ul>
          <li >
            <img :src="httpPath + sixReservice.imgUrl" alt="">
            <div class="text">
              <h2 :title="sixReservice.title">{{ sixReservice.title }}</h2>
              <p :title="sixReservice.description">{{sixReservice.description}}</p>
            </div>
          </li>
        </ul>
      </div>

      <div class="news_list_ziye" v-if="sevenReservice">
        <ul>
          <li >
            <div class="col-xs-12 col-md-6">
              <div class="col-xs-12 col-md-12">
                <img class="img-class-1 img-class-2" :src="httpPath + sevenReservice.imgUrl[0]" alt="">
              </div>
              <div class="col-xs-12 col-md-12 img-box-right">
                <div class="col-xs-12 col-md-6">
                  <img class="img-class-1 img-class-3" style="height: 135px;margin-bottom: 10px" :src="httpPath + sevenReservice.imgUrl[1]" alt="">
                </div>
                <div class="col-xs-12 col-md-6">
                  <img class="img-class-1 img-class-3" style="height: 135px;" :src="httpPath + sevenReservice.imgUrl[2]" alt="">
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-md-6">
              <div class="text" style="width: 100%;height: auto">
                <div>
                  <h2 style="padding-bottom: 12px;" :title="sevenReservice.list[0].title">{{ sevenReservice.list[0].title }}</h2>
                  <p style="margin-bottom: 5px" v-html="sevenReservice.list[0].content"></p>
                </div>
              </div>
            </div>

          </li>
        </ul>
      </div>

    </div>
  </div>

  <!--  <div class="wrap-lg">-->
<!--    <div class="">-->
<!--      <div class="top-title"><img src="@/assets/img/titleimg.png"><span class="title-text">关于我们</span></div>-->
<!--    </div>-->
<!--    <div class="list-box" v-loading="loading">-->
<!--      <div class="video-box-left">-->
<!--        <video :src="httpPath + firstReservice.videoUrl" controls="controls"-->
<!--               style="width: 100%; height: 100%; display: block"></video>-->
<!--      </div>-->
<!--      <div class="video-box-right">-->
<!--        <div class="video-right-content">-->
<!--          <div class="box-right-top">-->
<!--            <div class="box-right-title beyond-hide" >{{ firstReservice.title }}</div>-->
<!--            <div class="box-right-content" >-->
<!--              {{ firstReservice.description }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="box-right-bottom">-->
<!--            <div class="right-bottom-center">-->
<!--              <div class="bottom-center-item" v-for="item in firstReservice.list" :key="item.id">-->
<!--                <div style="margin: 10px;">-->
<!--                  <div class="center-item-title beyond-hide">-->
<!--                    <span :title="item.title">{{ item.title }}</span>-->
<!--                  </div>-->
<!--                  <div :title="item.content" class="center-item-content beyond-hide"-->
<!--                       v-html="item.content"></div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash;      第二快内容&ndash;&gt;-->
<!--    <div class="other-list-box" v-loading="loading">-->
<!--      <div class="other-box-count">-->
<!--        <div class="second-box-left">-->
<!--          <img :src="httpPath + secondReservice.imgUrl" alt=""/>-->
<!--        </div>-->
<!--        <div class="second-box-right">-->
<!--          <div style="padding: 20px;">-->
<!--            <div class="second-right-item" v-for="item in secondReservice.list" :key="item.id">-->
<!--              <div class="other-list-box-title beyond-hide">-->
<!--                <span :title="item.title">{{ item.title }}</span>-->
<!--              </div>-->
<!--              <div class="other-list-box-content multi-hide"-->
<!--                   v-html="item.content"></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash;     第三快内容&ndash;&gt;-->
<!--    <div class="other-list-box" v-loading="loading">-->
<!--      <div class="other-box-count">-->
<!--        <div class="third-box-left">-->
<!--          <div class="third-img-left">-->
<!--            <img :src="httpPath + thirdReservice.imgUrl[0]" alt=""/>-->
<!--          </div>-->
<!--          <div class="third-img-right">-->
<!--            <div class="img-right-top">-->
<!--              <img :src="httpPath + thirdReservice.imgUrl[1]" alt=""/>-->
<!--            </div>-->
<!--            <div class="img-right-bottom">-->
<!--              <img :src="httpPath + thirdReservice.imgUrl[2]" alt=""/>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="third-box-right">-->
<!--          <div class="other-list-box-title beyond-hide">-->
<!--            <span :title="thirdReservice.title">{{ thirdReservice.title }}</span>-->
<!--          </div>-->
<!--          <div class="other-list-box-content">-->
<!--            <span :title="thirdReservice.description" >{{ thirdReservice.description }}</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash; 第四块内容&ndash;&gt;-->
<!--    <div class="other-list-box">-->
<!--      <div class="other-box-count">-->
<!--        <div class="fourth-box-left">-->
<!--          <img :src="httpPath + fourthReservice.imgUrl" alt=""/>-->
<!--        </div>-->
<!--        <div class="fourth-box-right">-->
<!--          <div class="other-list-box-title beyond-hide">-->
<!--            <span :title="fourthReservice.list[0].title">{{ fourthReservice.list[0].title }}</span>-->
<!--          </div>-->
<!--          <p class="other-list-box-content" v-html="fourthReservice.list[0].content"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash; 第五块内容&ndash;&gt;-->
<!--    <div class="other-list-box">-->
<!--      <div class="other-box-count">-->
<!--        <div class="five-box-left">-->
<!--          <img :src="httpPath + fiveReservice.imgUrl" alt=""/>-->
<!--        </div>-->
<!--        <div class="five-box-right">-->
<!--          <div class="other-list-box-title beyond-hide">-->
<!--            <span :title="fiveReservice.title">{{ fiveReservice.title }}</span>-->
<!--          </div>-->
<!--          <p class="other-list-box-content">{{ fiveReservice.description }}</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash; 第六块内容&ndash;&gt;-->
<!--    <div class="other-list-box">-->
<!--      <div class="other-box-count">-->
<!--        <div class="six-box-left">-->
<!--          <img :src="httpPath + sixReservice.imgUrl" alt=""/>-->
<!--        </div>-->
<!--        <div class="six-box-right">-->
<!--          <div class="other-list-box-title beyond-hide">-->
<!--            <span :title="sixReservice.title">{{ sixReservice.title }}</span>-->
<!--          </div>-->
<!--          <p class="other-list-box-content">{{ sixReservice.description }}</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash; 第七块内容&ndash;&gt;-->
<!--    <div class="other-list-box">-->
<!--      <div class="other-box-count">-->
<!--        <div class="seven-box-left">-->
<!--          <div class="seven-left-top">-->
<!--            <img :src="httpPath + sevenReservice.imgUrl[0]" alt=""/>-->
<!--          </div>-->
<!--          <div class="seven-left-bottom">-->
<!--            <div class="seven-left-bottom-left">-->
<!--              <img :src="httpPath + sevenReservice.imgUrl[1]" alt=""/>-->
<!--            </div>-->
<!--            <div class="seven-left-bottom-right">-->
<!--              <img :src="httpPath + sevenReservice.imgUrl[2]" alt=""/>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="seven-box-right">-->
<!--          <div class="other-list-box-title beyond-hide">-->
<!--            <span :title="sevenReservice.list[0].title">{{ sevenReservice.list[0].title }}</span>-->
<!--            </div>-->
<!--          <div class="other-list-box-content" v-html="sevenReservice.list[0].content"></div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import { getLists } from '@/api/recommend/testing'

export default {
  name: 'index',
  data () {
    return {
      httpPath: '',
      firstReservice: {},
      secondReservice: {},
      thirdReservice: {},
      fourthReservice: {},
      fiveReservice: {},
      sixReservice: {},
      sevenReservice: {},
      loading: false
    }
  },
  mounted () {
    this.httpPath = this.commonUtils.getPara('httpPath')
    this.initData()
  },
  methods: {
    initData () {
      let param = {
        categoryCode: '1'
      }
      this.loading = true
      getLists(param).then(res => {
        this.loading = false
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          if (result.bizData && result.bizData.length > 0) {
            if (result.bizData.length > 0) {
              for (var i = 0; i < result.bizData.length; i++) {
                if (result.bizData[i].imgUrl !== '') {
                  var arr = result.bizData[i].imgUrl.split(',')
                  result.bizData[i].imgUrl = arr
                }
              }
            }
            this.firstReservice = result.bizData[0]
            this.secondReservice = result.bizData[1]
            this.thirdReservice = result.bizData[2]
            this.fourthReservice = result.bizData[3]
            this.fiveReservice = result.bizData[4]
            this.sixReservice = result.bizData[5]
            this.sevenReservice = result.bizData[6]
          }
        }
      }).catch(ex => {
        this.loading = false
        this.$message.error('列表加载失败，请稍后再试！')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list-box {
  display: inline-flex;
  margin-top: 40px;

  .video-box-left {
    width: 50%;
    height: 420px;
    background-color: black;
    border: 1px solid #f6b95e;

    video {
      width: 100%;
      height: 100%;
    }

  }

  .video-box-right {
    width: 50%;

    .video-right-content {
      //padding-left: 13px;
      width: 100%;

      .box-right-top {
        width: 100%;
        height: 210px;
        //padding-left: 8px;
        color: #4f080e;
        .box-right-title {
          font-weight: bold;
          font-size: 20px;
          margin-left: 18px;
        }
        .box-right-content {
          height: 162px;
          font-size: 14px;
          line-height: 30px;
          margin-left: 18px;
          padding: 10px 8px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 6;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .box-right-bottom {
        width: 100%;
        height: 210px;
        display: inline-flex;

        .right-bottom-center {
          margin-left: 10px;
          width: 100%;

          .bottom-center-item {
            background-color: #4f080e;
            width: 290px;
            height: 90px;
            float: left;
            margin: 8px 0 8px 8px;
            border: 1px solid #f6b95e;

            .center-item-title {
              font-size: 18px;
              font-weight: 700;
              color: #F6B95E;
            }

            .center-item-content {
              font-size: 14px;
              color: #F6B95E;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap
            }
          }
        }
      }
    }
  }
}

.other-list-box {
  margin-top: 40px;
  width: 100%;
  color: #F6B95E;

  .other-box-count {
    border: 1px solid #f6b95e;
    display: inline-flex;
    background-color: #4f080e;

    .other-list-box-title {
      font-weight: bold;
      font-size: 20px;
    }

    .other-list-box-content {
      font-size: 14px;
    }

    .second-box-left {
      width: 611px;
      height: 420px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .second-box-right {
      width: 613px;
      height: 420px;
    }

    .third-box-left {
      height: 500px;
      width: 640px;
      margin: 10px;
      display: inline-flex;

      .third-img-left {
        width: 310px;
        height: 100%;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .third-img-right {
        width: 310px;
        height: 100%;
        float: left;

        .img-right-top {
          width: 100%;
          height: 245px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .img-right-bottom {
          margin-top: 10px;
          width: 100%;
          height: 245px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .third-box-right {
      width: 524px;
      margin: 20px;
    }

    .fourth-box-left {
      width: 622px;
      height: 500px;
      margin: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .fourth-box-right {
      width: 522px;
      margin: 20px;
    }

    .five-box-left {
      width: 622px;
      height: 500px;
      margin: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .five-box-right {
      width: 522px;
      margin: 20px;
    }

    .six-box-left {
      width: 602px;
      height: 500px;
      margin: 30px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .six-box-right {
      width: 502px;
      margin: 30px;
    }

    .seven-box-left {
      width: 690px;
      margin: 30px;

      .seven-left-top {
        width: 100%;
        height: 500px;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .seven-left-bottom {
        width: 100%;
        height: 400px;
        float: left;
        margin-top: 20px;

        .seven-left-bottom-left {
          width: 335px;
          height: 100%;
          float: left;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .seven-left-bottom-right {
          width: 335px;
          height: 100%;
          float: left;
          margin-left: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .seven-box-right {
      width: 414px;
      margin: 30px;
    }
  }
}
.news_list_ziye li video {
  width: 445px;
  height: 280px;
  -o-object-fit: cover;
  object-fit: cover;
  float: left;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  -webkit-filter: brightness(1);
  filter: brightness(1);
}
.right-flex {
  display: flex;
  flex-direction: column;
  .top-box {
    flex: 1;
  }
  .bottom-box {
    flex: 1;
    padding-top: 10px;
  }
}
.news_list_ziye li .text p {
  -webkit-line-clamp: 6;
}
.news_list_ziye li img.img-class-1 {
  width: 96%;
}
.news_list_ziye li img.img-class-2 {
  margin-bottom: 10px;
}
.service-project .col-xs-12.col-md-6 {
  padding-right: 0;
  padding-left: 0;
}
.service-project .col-xs-12.col-md-12 {
  padding-right: 0;
  padding-left: 0;
}
.img-box-right {
  width: 96%;
}
@media (max-width: 1200px) {
  .news_list_ziye li img.img-class-0 {
    margin-bottom: 10px;
  }
  .news_list_ziye li img.img-class-3 {
    margin-bottom: 10px;
    height: 3rem !important;
  }
  .img-box-right {
    width: 100%;
  }
}

</style>
