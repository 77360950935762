import axios from '@/api/api-request'

const projectUrl = '/webRecommendTesting'

// 企业简介--关于我们管理-获取分页列表
export const getLists = (data) => {
  return axios.request({
    url: projectUrl + '/getLists.do',
    method: 'post',
    params: data
  })
}
// 招商加盟管理-获取分页列表
export const getJoinLists = (data) => {
  return axios.request({
    url: projectUrl + '/getJoinLists.do',
    method: 'post',
    params: data
  })
}
// 企业简介--首页显示-获取分页列表
export const firstGetLists = (data) => {
  return axios.request({
    url: projectUrl + '/firstGetLists.do',
    method: 'post',
    params: data
  })
}
